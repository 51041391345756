/* global */
.App {
  text-align: center;
}

body {
    margin: 0;
    background-color:#ffffff;
}
p {
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
}

/* Navbar */
.nav-header {
  background-color: #333;
  max-width: 100%;
  padding: 10px 0;
  top: 0;
  position: sticky;
  z-index: 5; 
}

.nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 70px;
}
.nav-logo a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;  
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px; 
  margin: 0;
  padding: 0;
  color: white;  
}

.nav-links a:hover {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

/* Hero Section */
.home-section {
  position: relative;
  height: 100vh;
  background-image: url('./media/homeBackground.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #eee;
}

.home-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
}

.home-overlay h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  margin-top: 0;
}

.home-overlay h2 {
  font-size: 1.5rem;
  margin-top: 0;
}

.scroll-down {
  margin-top: 20px;
  text-decoration: none;
  font-size: 1.2rem;
  color: #eee;
}

.scroll-down:hover {
  text-decoration: underline;
}

/* About section */
.about-me-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 800px;
}

.profile-picture {
  width: 20%;
  border-radius: 20%;
  object-fit: cover;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  margin-bottom: 20px;
}

.about-me-education {
  display: inline;
  border-radius: 20px;
  padding: 3px;
  border: 2px solid #eee;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}
.about-me-education h2{
  text-align: center;
}

.education-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 15px;
  margin: auto;
  padding: 0;
}

.education-item{
  text-align: center;
  background: #f7f7f7c0;
  width: 100%;
  border-radius: 20px;
  border: 2px solid #eee;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}

/* Skills section */
.skills h2,h3{
  text-align: center;
}
.skills-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  position: relative;
  width: 100%;
}

.skills-column-right {
  margin-right: 20%;
  margin-left: 30px;
}
.skills-column-left {
  margin-left: 20%;
  margin-right: 30px;
}

.skills-section ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 15px;
  padding: 0;
  list-style: none;
  /* margin-left: 20%;
  margin-right: 20%; */
}

.skills-section li {
  background: #f0f0f0;
  padding: 10px 10px;
  border-radius: 5px;
  border: 2px solid #eee;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width:fit-content;
}

/* project section */
.project-section {
  padding: 40px;
  margin: 20px auto;
}


.projects h2 {
  text-align: center;
  padding-top: 20px;
}
.projects-container {
  max-width: 70%;
  margin: 0 auto;
}

.proj-grid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  /* justify-items: center;
  align-items: stretch; */
  width: 70%;
  margin: auto;
}

.project-card {
  background: #f0f0f02f;
  padding: 20px;

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  height: fit-content;
  position: relative;
}

.project-card h3 {
margin: 0;
padding-bottom: 9px;
}


.project-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 6px;
}

.project-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1; 
}

.project-card::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  background-image: url('./media/zoom-in-white.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
  pointer-events: none;
}

.project-card:hover::before {
  opacity: 1;
}
.project-card:hover::after {
  opacity: 1;
}

/* Project Overlay Model */
.project-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}
.project-overlay-container {
  background: white;
  border-radius: 10px;
  max-width: 50%;
  width: 90%;
  min-height: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 90%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* cursor: s-resize; */
  overflow: hidden;
}

.overlay-header {
  background-color: #f0f0f0;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.proj-overlay-content {
  padding-top: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  width: 100%;
  overflow-y: auto; 
 
}

.proj-overlay-information{
  margin-left: 50px;
  margin-right: 50px;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 5%;
}
.proj-overlay-information a{
  color: #1a4a7e;
}
.proj-overlay-information a:hover{
  color: #007bff;
  text-decoration: underline;
  font-size:large;
}
.proj-overlay-img img{
  max-width: 80%;
  max-height: auto;
  height: auto;
  object-fit: contain;
  margin-bottom: 20px;
  /* width:  600px; */
  height: 400px;
  object-fit:cover;
  margin-bottom: 0;
}
.proj-overlay-img button{
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  width: 30%;
  margin-top: 0;
}

.proj-overlay-img button:hover{
  background-color: #2a8d2c;
}

.proj-overlay-footer {
  background-color: #f0f0f0;
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.footer-close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  width: 200px;
  color: rgb(0, 0, 0);
  background-color: #ee5656;
  border-style: groove;
  border-radius: 20px;
  color: #eee;
}
.footer-close-btn:hover {
  background-color: #e24c4c;
}

.contact-section {
  padding-bottom: 40px;
  margin-bottom: 10px auto;
}
.contact-section h2{

  margin-bottom: 6px;
}
.contact-section a{
  margin-bottom: 2px;
}
.contact-section p{
  margin-bottom: 0;
  margin-top: 0;
}
.contact-section a:hover{
  font-weight: bold;
}

/* Footer */
.footer {
  background-color: #333;
  color: #eee;
  height: auto;
  padding: 5px;
  /* margin-top: 5px; */
}
.footer p {
  margin: 2px;
  font-size: 16px;
}

/* responsive styles */
@media (max-width: 1200px) {
  .project-overlay-container {
      max-width: 70%;
  }
  .proj-grid {
    gap: 10px;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
}

@media (max-width: 768px) {
  .education-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .project-section {
    padding: 0;
    margin: 0;
  }
  .profile-picture {
    width: 30%;
    border-radius: 10%;
    margin-bottom: 10px;
  }
  .projects-container {
    max-width: 100%;
  }

  .project-overlay-container {
    max-width: 90%;
  }
  .nav-content {
    display:block;
    align-items: center;
    justify-content:center;
    max-width: 100%;
  }
  .nav-content a{
    align-items: center;
    justify-content:center;
  }
  .nav-links{
    align-items: center;
    justify-content:center;
  }
  .skills-section {
    grid-template-columns: repeat(1, 1fr); 
  }
  .skills-column-right{
    margin: 0;
  }
  .skills-column-left{
    margin: 0;
  }
  .home-section {
    height: 60vh;
  }
}

@media (max-width: 480px) {
  .project-overlay {
    padding: 0;
  }
  .profile-picture {
    width: 40%;
    border-radius: 10%;
    margin-bottom: 10px;
  }
  .proj-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .project-overlay-container {
    max-width: 99%;
  }

}